import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect } from 'react'
import React from 'react'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

export function useUpsell(items) {
  const [upsellItems, setUpsellItems] = React.useState(
    (items || []).slice(0, 3),
  )
  const { cart, isFetching } = useLocaleCartLogic()

  function sortItems() {
    setUpsellItems(() => {
      if (isFetching) {
        return items.slice(0, 3)
      }

      const productSlugs = (cart?.lineItems || []).map(
        item => item.variant.slug,
      )
      return items.filter(item => !productSlugs.includes(item.product[0].slug))
    })
  }

  useEffect(() => {
    sortItems()
  }, [items, cart?.lineItems])

  return upsellItems
}
