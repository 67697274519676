/** @jsx jsx */
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Grid, jsx, Flex, Link } from 'theme-ui'
import {
  useAnalytics,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import { useContext } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { ChordCartContext } from '../../Mini-Cart/CartContext'
import { intToWord } from '../../../utils/compare/intToWord'
import useMatchMedia from '../../../hooks/utils/use-match-media'
import Text from '~/components/IntlComponents/Text'
import Heading from '~/components/IntlComponents/Heading'
import { toURL } from '~/utils/intl/localePrefix'
import TrashCanSVG from '~/assets/images/icons/trash-can.svg'
import PlusSVG from '~/assets/images/icons/plus.svg'
import MinusSVG from '~/assets/images/icons/minus.svg'
import { intervalToWord } from '~/utils/misc/index.js'
import LineItemPrice from '~/components/Cart/LineItemPrice'
import IconButton from '~/components/Generic/IconButton'
import SubBlurb from '~/components/Generic/SubBlurb'
import GiftCardInputs from '~/components/Cart/GiftCardInputs'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'
import useGetProductOrKitByLocale from '~/utils/intl/context/hooks/use-get-product-or-kit-by-locale'
import TriggerWarningAroundDisabledButtonsWrapper from '~/components/IntlComponents/TriggerWarningAroundDisabledButtonsWrapper'
import {
  useProductOptions,
  useVariantMainImage,
} from '~/hooks/components/use-product-main-image'
import ImgLoader from '~/components/Generic/ImgLoader'
import { productPathBuilder } from '~/utils/builder/index.cjs'
import useProduct from '~/hooks/components/use-product'

const TINY_TEXT_STYLES = {
  color: 'primary',
  fontSize: ['12px', '14px'],
  lineHeight: '125%',
}

export const CartProduct = props => {
  const { trackProductClicked } = useAnalytics()
  const showPill = useMatchMedia('(min-width: 1024px)')
  const translate = useTranslate()
  const ctx = useContext(ChordCartContext)
  const {
    modifyQuantityWithLocaleRestrictions: modifyLineItem,
    removeFromCart,
    hasGroupHitQuantityLimit,
  } = useLocaleCartLogic()
  const {
    variant,
    subscriptionLineItems,
    quantity,
    id,
    isMobile,
    updateGiftCardSubmission,
    giftCards,
    getProduct,
  } = props
  const { name, slug, sku } = variant
  const product = useProduct(slug) ?? {}
  const { optionValues } = useProductOptions({ slug, sku: sku })
  const { image } = useVariantMainImage({ slug, sku: sku })

  const productOrKitByLocale = useGetProductOrKitByLocale({ slug })

  const isDisabledFromIncreasingQuantity = hasGroupHitQuantityLimit(slug)

  const [{ presentation: packSize }] = !!optionValues.length
    ? optionValues
    : [{ presentation: '' }]

  const isSubscription =
    !!subscriptionLineItems && subscriptionLineItems.length > 0

  const isDonation = variant.name.toLowerCase() === 'donation'

  const renewString = isSubscription
    ? translate('cart.renew_string', {
        length: intToWord(subscriptionLineItems[0].intervalLength),
        units: subscriptionLineItems[0].intervalUnits,
      })
    : isDonation
    ? ''
    : translate('cart.one_time_purchase')

  const handleQtyChange = async newQty => {
    try {
      await modifyLineItem(id, newQty)
      document.dispatchEvent(new CustomEvent('dk_cart_updated'))
    } catch (err) {
      console.error(err)
    }
  }

  const handleRemoveItem = async () => {
    try {
      await removeFromCart({ lineItemId: id })
      if (isDonation) {
        ctx.donationMemo.sethasDonation(false)
      }
    } catch (err) {
      console.error(err)
    }
    document.dispatchEvent(new CustomEvent('dk_cart_updated'))
  }

  const cmsProduct = getProduct(slug)

  const pageSlug = toURL(productPathBuilder(slug))
  const presentationIsSize = !!optionValues.filter(
    el => el.option_type?.[0]?.slug === 'size',
  ).length

  const presentationIsShoeSize = !!optionValues.filter(
    el => el.option_type?.[0]?.slug === 'shoe-size',
  ).length

  // If free promotion, disable link, disable quantity
  const isFreePromotionItem = cmsProduct?.isFreePromotionItem

  return (
    <Box
      sx={{
        mt: ['20px'],
        pb: ['20px'],
        borderBottom: '1px solid #FF5A4733',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: isMobile ? '0.22fr 0.75fr' : '0.15fr 0.85fr',
          columnGap: isMobile ? '16px' : '32px',
        }}
      >
        <Flex
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: isMobile ? '88px' : '112px',
          }}
        >
          <Flex
            sx={{
              width: '100%',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '2px solid #FFDD00',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '150px',
              maxHeight: '150px',
            }}
          >
            {image && (
              <ImgLoader
                image={image}
                alt={name}
                forwardSx={{
                  width: '100%',
                  objectFit: 'contain',
                  aspectRatio: 1,
                }}
              />
            )}
            {!image && variant?.images?.[0]?.smallUrl && (
              <img
                sx={{
                  width: '100%',
                  objectFit: 'contain',
                  aspectRatio: 1,
                }}
                src={variant.images[0].smallUrl}
              />
            )}
          </Flex>
        </Flex>
        <Box>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Flex sx={{ alignItems: 'center', mb: ['8px', '12px'] }}>
              <Link
                as={GatsbyLink}
                sx={{
                  cursor:
                    isDonation || isFreePromotionItem ? 'default' : 'pointer',
                }}
                to={isFreePromotionItem ? '' : pageSlug}
                disabled={isFreePromotionItem}
                onClick={e => {
                  if (isDonation || isFreePromotionItem) {
                    e.preventDefault()
                    return
                  }
                  trackProductClicked({ product })
                }}
              >
                <Heading
                  sx={{
                    color: 'primary',
                    fontFamily: 'heading',
                    fontWeight: 400,
                    fontSize: ['16px', '24px'],
                    lineHeight: 1,
                    '&:hover': {
                      textDecoration: isDonation ? 'none' : 'underline',
                    },
                  }}
                >
                  {productOrKitByLocale?.name ?? name}
                </Heading>
              </Link>
              {isSubscription && showPill && (
                <span
                  sx={{
                    ml: '10px',
                    bg: 'accent',
                    color: 'primary',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    px: '8px',
                    py: '8px',
                    fontSize: '14px',
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                  }}
                >
                  {translate('cart.renews', {
                    interval: intervalToWord(
                      subscriptionLineItems[0]?.intervalUnits || 'monthly',
                    ),
                  })}
                </span>
              )}
            </Flex>
            <LineItemPrice {...props} />
          </Flex>
          {!isDonation && (
            <Text
              as="p"
              sx={{
                ...TINY_TEXT_STYLES,
                mt: '8px',
              }}
            >
              {presentationIsSize
                ? `${translate('cart.n_pack', {
                    amount: packSize,
                  })} • `
                : presentationIsShoeSize
                ? `${translate('cart.size', { size: packSize })} • `
                : packSize}
              {!isMobile && <> {renewString}</>}
            </Text>
          )}
          {isMobile && (
            <Text as="p" sx={TINY_TEXT_STYLES}>
              {renewString}
            </Text>
          )}
          {/* Quantity Selector */}
          <Flex sx={{ mt: ['12px', '24px'], justifyContent: 'space-between' }}>
            <Flex>
              <IconButton
                onClick={() => handleQtyChange(quantity - 1)}
                disabled={quantity === 1 || isFreePromotionItem}
              >
                <MinusSVG />
              </IconButton>
              <Text
                as="p"
                mx="12px"
                sx={{
                  fontSize: '16px',
                  lineHeight: '125%',
                  textAlign: 'center',
                  color: 'primary',
                  fontWeight: 400,
                  mt: '1px',
                  fontFamily: 'heading',
                }}
              >
                {quantity}
              </Text>
              <TriggerWarningAroundDisabledButtonsWrapper
                disabledByLocaleLimitations={isDisabledFromIncreasingQuantity}
              >
                <IconButton
                  disabled={
                    isDisabledFromIncreasingQuantity || isFreePromotionItem
                  }
                  onClick={() => handleQtyChange(quantity + 1)}
                >
                  <PlusSVG />
                </IconButton>
              </TriggerWarningAroundDisabledButtonsWrapper>
            </Flex>
            <IconButton onClick={handleRemoveItem}>
              <TrashCanSVG />
            </IconButton>
          </Flex>
          {isSubscription && !isMobile && <SubBlurb isMobile={isMobile} />}
        </Box>
      </Grid>
      {!!giftCards.length && (
        <GiftCardInputs
          updateGiftCardSubmission={updateGiftCardSubmission}
          giftCards={giftCards}
        />
      )}
      {isSubscription && isMobile && <SubBlurb isMobile={isMobile} />}
    </Box>
  )
}

CartProduct.propTypes = {
  adjustments: PropTypes.arrayOf(PropTypes.shape({})),
  crossSold: PropTypes.bool.isRequired,
  displayAmount: PropTypes.string.isRequired,
  giftCards: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number.isRequired,
  metadata: PropTypes.shape({}),
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  singleDisplayAmount: PropTypes.string.isRequired,
  subscriptionLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.string.isRequired,
  variant: PropTypes.shape({}).isRequired,
  variantId: PropTypes.number.isRequired,
}
