import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  useAnalytics,
  useCart,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import CartPage from '~/components/Cart/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useTikTokHelper } from '~/components/TikTokHelper'
import ErrorBoundary from '~/components/Generic/ErrorBoundary'

const Cart = ({ data }) => {
  const translate = useTranslate()
  const { trackCartViewed } = useAnalytics()
  const { forgetCart } = useCart()
  const { viewContent } = useTikTokHelper()

  useEffect(() => {
    viewContent({ description: translate('cart.page_title') })
    if (typeof window !== 'undefined') {
      setTimeout(trackCartViewed, 500)
    }
  }, [])

  const callback = forgetCart

  return (
    <Layout navBackgroundColor="#FF5A47">
      <Metadata title={translate('cart.page_title')} />
      <ErrorBoundary handleCallback={callback} callbackTitle="Clear Cart">
        <CartPage cmsCartData={data['contentfulCart']} />
      </ErrorBoundary>
    </Layout>
  )
}

export const query = graphql`
  query CartPage($locale: String) {
    contentfulCart(node_locale: { eq: $locale }) {
      id
      showItemUpsell
      showKitUpsell
      shortDonationDescription
      longDonationDescription
      shippingNote
      contentful_id
      guarantee
      emptyCartText
      emptyCartCta
      emptyCartImage {
        gatsbyImageData
      }
      upsell {
        contentful_id
        id
        sku
        product {
          slug
          name
        }
        optionValues {
          presentation
        }
        globalTradeItemNumber
        price
        regularPrice
        weight
        totalPackageWeight
        soldOut
        comingSoon
        mainImage {
          gatsbyImageData
        }
      }
    }
  }
`

export default Cart
